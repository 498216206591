import React, { Component } from "react";
import styled from "styled-components";

import Barcode from "react-barcode";

class PrintBarcode extends Component {
  constructor(props) {
    super(props);

    if (this.props.label !== undefined) {
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = { allowdisp: true };
    }
  }

  render() {
    let height = 35;
    return (
      <OuterWrap>
        <Wrapper>
          {this.state.allowdisp && (
            <>
              <TopSpacer />
              <Header>
                <HeaderLeft>
                  <Barcode
                    lineColor="white"
                    value={this.state.barcode}
                    width="1"
                    height={height}
                    fontSize="10"
                  />
                  <br />
                  <span style={{ textAlign: "center", color: "white" }}>
                    {this.state.name}
                  </span>
                  <br />
                  <span style={{ textAlign: "center", color: "white" }}>
                    {this.state.price}
                  </span>
                </HeaderLeft>
              </Header>
              <Spacer />
              <Header>
                <HeaderLeft>
                  <Barcode
                    lineColor="white"
                    color="white"
                    value={this.state.barcode}
                    width="1"
                    height={height}
                    fontSize="10"
                  />
                  <br />
                  <span style={{ textAlign: "center", color: "white" }}>
                    {this.state.name}
                  </span>
                  <br />
                  <span style={{ textAlign: "center", color: "white" }}>
                    {this.state.price}
                  </span>
                </HeaderLeft>
              </Header>
              <Spacer />
              <Header>
                <HeaderLeft>
                  <Barcode
                    value={this.state.barcode}
                    width="1"
                    height={height}
                    fontSize="10"
                  />
                  <br />
                  <span style={{ textAlign: "center" }}>{this.state.name}</span>
                  <br />
                  <span style={{ textAlign: "center" }}>
                    {this.state.price}
                  </span>
                </HeaderLeft>
              </Header>
            </>
          )}
        </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  width: 100%; /* Development preview */
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */

  /*width: 14cm;*/

  width: 10cm;
  /*height: 20cm;*/
  /*height: 27cm;*/

  /*height: 19cm;
  margin-top: 0;
  margin-left: 0.5cm;*/

  margin: 0 auto;
  background-color: #fff;
  /*border: 2px solid black;*/

  /* padding:5mm; */
  padding: 3mm;

  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;

  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  /*border-bottom: 1px solid black;
  padding-bottom: 1mm;*/

  margin-top: 1cm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const TopSpacer = styled.div`
  width: 100%;
  height: 1.5cm;
`;

const Spacer = styled.div`
  width: 100%;
  height: 0.1cm;
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 100%;
  float: left;

  text-align: center;
`;

export default PrintBarcode;

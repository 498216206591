import React, { Component } from "react";
import styled from "styled-components";

import moment from "moment";

class DeliveryOrder extends Component {
  constructor(props) {
    super(props);
    if (this.props.label !== undefined) {
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = { allowdisp: true };
    }
  }

  renderItems() {
    let disp = this.state.up_detail.map((dt) => {
      if (dt.packaged === 0) return <></>;
      return (
        <TableRow key={dt.id_purchase_order_retur_detail}>
          <TableCol className="tname">{dt.up_detail.variant_name}</TableCol>
          <TableCol className="align-center tqty">
            {dt.packaged}{" "}
            {dt.up_detail.up_variant.up_item.up_unit !== null
              ? dt.up_detail.up_variant.up_item.up_unit.unit
              : ""}
          </TableCol>
          <TableCol className="align-center tqty">{dt.collie}</TableCol>
        </TableRow>
      );
    });

    return disp;
  }

  render() {
    return (
      <OuterWrap>
        <Wrapper>
          {this.state.allowdisp && (
            <>
              <Header>
                <HeaderLeft>
                  <HeaderLogo src="logo.png" />
                </HeaderLeft>
                <HeaderRight>
                  <HeaderInvoiceWrap>
                    <HeaderInvoice>Delivery Order</HeaderInvoice>
                    <HeaderInvoiceNo>
                      No: DO-{this.state.id_delivery}
                    </HeaderInvoiceNo>
                  </HeaderInvoiceWrap>
                </HeaderRight>
              </Header>
              <Shipping>
                <ShippingRow>
                  <BoldText>SHIPPING DETAIL</BoldText>
                </ShippingRow>
                <ShippingRow>
                  <ShippingLeft>Shipping Date </ShippingLeft>
                  <ShippingRight>
                    {moment(this.state.delivery_date).format("DD MMM YYYY")}
                  </ShippingRight>
                </ShippingRow>
                {/* {this.state.up_s_o.up_customer !== null && (
                  <>
                    <ShippingRow>
                      <ShippingLeft>Recipient Name </ShippingLeft>
                      <ShippingRight>
                        {this.state.up_s_o.up_customer.fullname}
                      </ShippingRight>
                    </ShippingRow>
                    <ShippingRow>
                      <ShippingLeft>Recipient Phone </ShippingLeft>
                      <ShippingRight>
                        {this.state.up_s_o.up_customer.phone}
                      </ShippingRight>
                    </ShippingRow>
                    <ShippingRow>
                      <ShippingLeft>Delivery Address </ShippingLeft>
                      <ShippingRight>
                        {this.state.up_s_o.up_customer.up_address[0].street},{" "}
                        {this.state.up_s_o.up_customer.up_address[0].district},{" "}
                        {this.state.up_s_o.up_customer.up_address[0].city},{" "}
                        {this.state.up_s_o.up_customer.up_address[0].province}
                      </ShippingRight>
                    </ShippingRow>
                  </>
                )} */}
                {/* to show shipping detail template, controlled by boolean below*/}
                {false && (
                  <>
                    <ShippingRow>
                      <ShippingLeft>Recipient Name </ShippingLeft>
                      <ShippingRight>
                        {/* {this.state.up_s_o.up_customer.fullname} */}
                        {this.state.up_p_o.up_supplier.supplier_name}
                      </ShippingRight>
                    </ShippingRow>
                    <ShippingRow>
                      <ShippingLeft>Recipient Phone </ShippingLeft>
                      <ShippingRight>
                        {this.state.up_p_o.up_supplier.phone}
                        {/* {this.state.up_s_o.up_customer.phone} */}
                      </ShippingRight>
                    </ShippingRow>
                    <ShippingRow>
                      <ShippingLeft>Delivery Address </ShippingLeft>
                      <ShippingRight>
                        {this.state.up_p_o.up_supplier.address}
                        {this.state.up_p_o.up_supplier.district}
                        {this.state.up_p_o.up_supplier.city}
                      </ShippingRight>
                    </ShippingRow>
                  </>
                )}
                {/* end of shipping detail template */}
              </Shipping>
              <TableContents>
                <thead>
                  <TableRow>
                    <TableHeader>ITEM DESCRIPTION</TableHeader>
                    <TableHeader>QTY</TableHeader>
                    <TableHeader>COLLIE</TableHeader>
                  </TableRow>
                </thead>
                <tbody>
                  <TableRow>
                    <TableCol />
                    <TableCol />
                  </TableRow>
                  {this.renderItems()}
                </tbody>
              </TableContents>
              <Totals>
                {/*
          <TotalRow>
            <TotalLeft></TotalLeft>
            <TotalMid>Total Item</TotalMid>
            <TotalRight>3 item(s)</TotalRight>
          </TotalRow>
          */}
                <TotalRow className="grandtotalrow">
                  <TotalLeft></TotalLeft>
                  <TotalMid className="grandtotalcol">Courier</TotalMid>
                  <TotalRight className="grandtotalcol">
                    {this.state.delivery_courier}
                  </TotalRight>
                </TotalRow>
                {/*
          <TotalRow className="grandtotalrow">
            <TotalLeft></TotalLeft>
            <TotalMid className="grandtotalcol">Shipping Cost</TotalMid>
            <TotalRight className="grandtotalcol">IDR 200.000</TotalRight>
          </TotalRow>
          */}
              </Totals>
              <Footer>
                <FooterRow className="align-center">
                  <FooterId>
                    <BoldText>CV. Sinar Jaya</BoldText>
                    <RegularText> - </RegularText>
                    <RegularText>
                      Address: Jl. Simolawang Baru Sekolahan IV No. 5-7,
                      Surabaya
                    </RegularText>
                    <RegularText> - </RegularText>
                    <RegularText>Phone: (031) 3726999</RegularText>
                  </FooterId>
                </FooterRow>
              </Footer>
            </>
          )}
        </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  width: 100%; /* Development preview */
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */

  /*width: 14cm;*/

  width: 20cm;
  height: 27cm;

  /*height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;*/

  margin: 0 auto;
  background-color: #fff;
  border: 2px solid black;

  /* padding:5mm; */
  padding: 3mm;

  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;

  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 29%;
  float: left;

  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 70%;
  margin: auto;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const HeaderInvoiceWrap = styled.div`
  display: block;
  width: 100%;
`;

const HeaderInvoice = styled.h1`
  text-align: right;
  margin-bottom: 1.5mm;
  font-size: 2em;
`;

const HeaderInvoiceNo = styled.div`
  font-size: 1.3em;
  text-align: right;
  margin-bottom: 1.5mm;
`;

const Shipping = styled.div`
  display: block;
  position: relative;
  padding: 2mm;
  margin: 3mm 0;
  background: #f3f4f8;
  border: 1px solid #ddd;
`;

const ShippingRow = styled.div`
  display: block;
  position: relative;
  width: 100%;

  padding-top: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const ShippingLeft = styled.div`
  display: block;
  position: relative;
  width: 24%;
  float: left;

  &:after {
    position: absolute;
    content: ":";
    right: 0;
  }
`;

const ShippingRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  padding-left: 5%;
  float: left;
`;

const Totals = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 8mm;
`;

const TotalRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 2mm;

  &.grandtotalrow {
    padding-top: 1.5mm;
  }

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const TotalLeft = styled.div`
  display: block;
  position: relative;
  width: 50%;
  float: left;
  height: 1mm;
`;

const TotalMid = styled.div`
  display: block;
  position: relative;
  width: 25%;
  float: left;

  &.grandtotalcol {
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const TotalRight = styled.div`
  text-align: right;
  display: block;
  position: relative;
  width: 24%;
  padding-right: 1%;
  float: left;

  &.grandtotalcol {
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const TableContents = styled.table`
  display: table;
  position: relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size: 11px;
`;

const TableRow = styled.tr`
  display: table-row;
  position: relative;
  width: 100%;
`;

const TableHeader = styled.th`
  display: table-cell;
  position: relative;
  background: black;
  color: white;
  margin-bottom: 2mm;
  font-size: 1.2em;
  padding: 1%;
`;

const TableCol = styled.td`
  display: table-cell;
  position: relative;
  padding: 1%;
  vertical-align: top;
  font-size: 1em;

  &.align-right {
    text-align: right;
  }
  &.tname {
    width: 40%;
  }
  &.tqty {
    width: 10%;
  }
  &.tprice {
    width: 25%;
  }
  &.ttotal {
    width: 25%;
  }
`;

const BoldText = styled.div`
  font-weight: bold;
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;

  &.shippingaddress {
    margin-bottom: 2mm;
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
`;

const Footer = styled.div`
  display: block;
  margin-top: 2mm;
`;

const FooterRow = styled.div`
  display: block;
  position: relative;
  width: 100%;

  padding-top: 2mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const FooterId = styled.div`
  border-top: 1px solid black;
  padding: 2mm 1.5mm;

  > div {
    display: inline-block;
    margin: 1mm 0.5mm;
  }
`;

export default DeliveryOrder;

import React, { Component } from "react";
import styled from "styled-components";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Invoice extends Component {
  constructor(props) {
    super(props);

    if (this.props.label !== undefined) {
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = { allowdisp: true };
    }
  }

  renderItems() {
    let disp = this.state.items.map((dt) => {
      return (
        <TableRow>
          <TableCol className="tname">{dt.name}</TableCol>
          <TableCol className="align-right tqty">{dt.qty}</TableCol>
          <TableCol className="align-right tprice">
            IDR {numberFormat(dt.price)}
          </TableCol>
          <TableCol className="align-right ttotal">
            IDR {numberFormat(dt.price * dt.qty)}
          </TableCol>
        </TableRow>
      );
    });

    return disp;
  }

  convertDate(datestr) {
    let date_start = new Date(datestr.substr(0, 10));

    let start_year = date_start.getFullYear();
    let start_month = date_start.getMonth();
    let start_date = date_start.getDate();

    let returnstr =
      start_date.toString().padStart(2, "0") +
      " " +
      monthNames[start_month] +
      " " +
      start_year;

    return returnstr;
  }

  render() {
    return (
      <OuterWrap>
        <Wrapper>
          {this.state.allowdisp && (
            <>
              <Header>
                <HeaderLeft>
                  <HeaderLogo src="logo.png" />
                </HeaderLeft>
                <HeaderRight>
                  <HeaderInvoiceWrap>
                    <HeaderInvoice>INVOICE</HeaderInvoice>
                    <HeaderInvoiceNo>No: 123456789</HeaderInvoiceNo>
                  </HeaderInvoiceWrap>
                </HeaderRight>
              </Header>
              <Recipient>
                <RecipientRow>
                  <RecipientLeft>Order Date </RecipientLeft>
                  <RecipientRight>20 September 2022</RecipientRight>
                </RecipientRow>
                <RecipientRow>
                  <RecipientLeft>Customer </RecipientLeft>
                  <RecipientRight>Jane Doe</RecipientRight>
                </RecipientRow>
                <RecipientRow>
                  <RecipientLeft>Phone </RecipientLeft>
                  <RecipientRight>0812 3456 789</RecipientRight>
                </RecipientRow>
              </Recipient>
              <TableContents>
                <TableRow>
                  <TableHeader>ITEM DESCRIPTION</TableHeader>
                  <TableHeader>QTY</TableHeader>
                  <TableHeader>PRICE</TableHeader>
                  <TableHeader>TOTAL</TableHeader>
                </TableRow>
                <TableRow>
                  <TableCol />
                  <TableCol />
                  <TableCol />
                  <TableCol />
                </TableRow>
                <TableRow>
                  <TableCol className="tname">Product 01</TableCol>
                  <TableCol className="align-center tqty">3</TableCol>
                  <TableCol className="align-right tprice">
                    IDR 250.000
                  </TableCol>
                  <TableCol className="align-right ttotal">
                    IDR 750.000
                  </TableCol>
                </TableRow>
              </TableContents>
              <Totals>
                <TotalRow>
                  <TotalLeft></TotalLeft>
                  <TotalMid>Total</TotalMid>
                  <TotalRight>IDR 12.500.000</TotalRight>
                </TotalRow>
                <TotalRow>
                  <TotalLeft></TotalLeft>
                  <TotalMid>Shipping Cost</TotalMid>
                  <TotalRight>IDR 225.000</TotalRight>
                </TotalRow>
                {this.state.discount > 0 && (
                  <TotalRow>
                    <TotalLeft></TotalLeft>
                    <TotalMid>Discount</TotalMid>
                    <TotalRight>IDR 0</TotalRight>
                  </TotalRow>
                )}
                <TotalRow className="grandtotalrow">
                  <TotalLeft></TotalLeft>
                  <TotalMid className="grandtotalcol">GRAND TOTAL</TotalMid>
                  <TotalRight className="grandtotalcol">
                    IDR 12.750.000
                  </TotalRight>
                </TotalRow>
              </Totals>
              <Footer>
                <FooterRow>
                  <Shipping>
                    <BoldText className="shippingaddress">
                      SHIPPING ADDRESS
                    </BoldText>
                    <RegularText>Jane Doe</RegularText>
                    <RegularText>
                      Jl. Jendral Sudirman Kav-2 no.204, Jakarta Selatan
                    </RegularText>
                    <RegularText>0812 3456 789</RegularText>
                  </Shipping>
                </FooterRow>
                <FooterRow className="align-center">
                  <FooterId>
                    <BoldText>Company Name</BoldText>
                    <RegularText> - </RegularText>
                    <RegularText>Address: Full Address</RegularText>
                    <RegularText> - </RegularText>
                    <RegularText>Phone: (021) 987654321</RegularText>
                  </FooterId>
                </FooterRow>
              </Footer>
            </>
          )}
        </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  width: 100%; /* Development preview */
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */

  /*width: 14cm;*/

  width: 12.5cm;

  /*height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;*/

  margin: 0 auto;
  border: 2px solid black;

  /* padding:5mm; */
  padding: 30px;

  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;

  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 29%;
  float: left;

  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 70%;
  margin: auto;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const HeaderInvoiceWrap = styled.div`
  display: block;
  width: 100%;
`;

const HeaderInvoice = styled.h1`
  text-align: right;
  margin-bottom: 1.5mm;
  font-size: 2em;
`;

const HeaderInvoiceNo = styled.div`
  font-size: 1.3em;
  text-align: right;
  margin-bottom: 1.5mm;
`;

const Recipient = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 3mm;
  padding-bottom: 5mm;
`;

const RecipientRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const RecipientLeft = styled.div`
  display: block;
  position: relative;
  width: 13%;
  float: left;

  &:after {
    position: absolute;
    content: ":";
    right: 0;
  }
`;

const RecipientRight = styled.div`
  display: block;
  position: relative;
  width: 81%;
  padding-left: 5%;
  float: left;
`;

const Totals = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 8mm;
`;

const TotalRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 2mm;

  &.grandtotalrow {
    padding-top: 5mm;
  }

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const TotalLeft = styled.div`
  display: block;
  position: relative;
  width: 50%;
  float: left;
  height: 1mm;
`;

const TotalMid = styled.div`
  display: block;
  position: relative;
  width: 25%;
  float: left;

  &.grandtotalcol {
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const TotalRight = styled.div`
  text-align: right;
  display: block;
  position: relative;
  width: 24%;
  padding-right: 1%;
  float: left;

  &.grandtotalcol {
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const TableContents = styled.table`
  display: table;
  position: relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size: 11px;
`;

const TableRow = styled.tr`
  display: table-row;
  position: relative;
  width: 100%;
`;

const TableHeader = styled.th`
  display: table-cell;
  position: relative;
  background: black;
  color: white;
  margin-bottom: 2mm;
  font-size: 1.2em;
  padding: 1%;
`;

const TableCol = styled.td`
  display: table-cell;
  position: relative;
  padding: 1%;
  vertical-align: top;
  font-size: 1em;

  &.align-right {
    text-align: right;
  }
  &.tname {
    width: 40%;
  }
  &.tqty {
    width: 10%;
  }
  &.tprice {
    width: 25%;
  }
  &.ttotal {
    width: 25%;
  }
`;

const BoldText = styled.div`
  font-weight: bold;
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;

  &.shippingaddress {
    margin-bottom: 3mm;
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
`;

const Shipping = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 5mm;
`;

const Footer = styled.div`
  display: block;
  border-top: 1px dotted #999;
  padding-top: 2mm;
  padding-bottom: 2mm;
  margin-top: 2mm;
`;

const FooterRow = styled.div`
  display: block;
  position: relative;
  width: 100%;

  padding-top: 2mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const FooterId = styled.div`
  border-top: 1px solid black;
  padding: 2mm 1.5mm;

  > div {
    display: inline-block;
    margin: 1mm 0.5mm;
  }
`;

export default Invoice;

import React, { Component } from "react";
import { connect } from "react-redux";
import { saveLogin } from "../../redux/actions/loginActions";
import { fetchUser } from "../../redux/actions/userActions";
import { login } from "../../redux/actions/devLoginActions";

import {
  Anchor,
  Avatar,
  Box,
  Button,
  Collapsible,
  FormField,
  Image,
  Layer,
  Text,
  TextInput,
  ResponsiveContext,
  Select,
} from "grommet";

import { Menu, Logout, User, Close, Add, Ad } from "grommet-icons";

import { apiconn } from "../../apiconn"; //axios connectors are here
import "react-toastify/dist/ReactToastify.css";
import { clear } from "redux-localstorage-simple";

class Header extends Component {
  apiheader = {
    headers: { Authorization: "Bearer " + this.props.auth.token },
  };
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
      sidebarPanel: false,

      layerChannel: false,
      layerAddChannel: false,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    if (this.props.login.id_user < 1) this.props.history.replace("/");
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    // if(this.props.login.id_user < 1)
    //   this.doLogout();

    this._fetchUserData();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timout);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  toggleSidebar() {
    this.setState({ sidebarPanel: this.state.sidebarPanel ? false : true });
  }

  openChannel() {
    this.setState({ layerChannel: true });
  }
  openAddChannel() {
    this.setState({ layerAddChannel: true });
  }

  closeLayer() {
    this.setState({
      layerChannel: false,
    });
  }
  closeAddChannel() {
    this.setState({ layerAddChannel: false });
  }

  doLogout = () => {
    this.props.dispatchLOGIN(false, "");
    this.props.saveLogin(0);
    this.props.fetchUser("reset");
    clear();
    this.props.history.replace("/");
  };

  // _timerFunctions() {
  //   this._fetchUserData();

  //   timercount++;
  //   if (timercount > 60) timercount = 1;
  // }

  async _fetchUserData() {
    if (this.props.login.id_user < 1) {
      this.doLogout();
    } else {
      apiconn.getApi
        .get("/user/" + this.props.login.id_user, this.apiheader)
        .then((response) => {
          if (response.status >= 200 && response.status <= 202) {
            //success
            let resp = response.data;
            // console.log(resp);
            // if(resp.id_user == 3){
            //    let access = ["master_data", "catalog", "show_capital_price", "purchase_order_all", "purchase_order_receipt", "marketing", "sales_order_all", "sales_order_shipping", "sales_chart_statistics", "complaint", "complaint_shipping", "finance_all", "finance_crosscheck_ongkir_cash", "finance_bank_operational", "warehouse", "reports_all", "reports_admin", "pro_master_data", "pro_items", "pro_purchase_order_all", "pro_purchase_order_receipt", "pro_batch_all", "pro_batch_parts_input", "pro_batch_parts_output", "pro_assembly", "pro_qc", "pro_packing", "pro_surat_jalan", "pro_warehouse", "pro_finance"];
            //
            //    resp.accessobj = access;
            //  }

            // if(resp.deleted == 1){ //logout if user is deleted
            //     this.doLogout();
            // }
            // else {
            this.props.fetchUser(resp.user);
            // }
            const timout = setTimeout(() => {
              this._fetchUserData();
            }, 3000);
            this.setState({ timout });
          } else if (response.status === 203) {
            //fail
            //this.props.history.replace("/");
          } else {
            //console.log("Request failed");
          }
        })
        .catch((error) => {
          if (error !== undefined) {
            if (error.response !== undefined) {
              if (error.response.status !== undefined) {
                if (error.response.status === 401) {
                  //unauthorized
                  this.doLogout();
                }
              }
            }
          }

          //this._backgroundFetchAttempt();
        });
    }
  }

  render() {
    return (
      <ResponsiveContext.Consumer>
        {(size) => (
          <>
            <Box id="top-panel" direction="row" align="center" justify="between" elevation="small">
              {this.state.width > 960 ? (
                <Box direction="row" align="center" gap="30px">
                  <Box id="logo">
                    <Image src="logo.png" />
                  </Box>
                  <Menu className="clickable" onClick={(e) => this.props.toggleMenu()} />
                </Box>
              ) : (
                <Menu className="clickable" onClick={() => this.props.openNav()} />
              )}
              <Box direction="row" align="center" justify="end" gap="30px">
                {this.props.user.data.vars.subsystem !== "waluyojaya" &&
                <Box
                  id="channel-select"
                  className="clickable"
                  direction="row"
                  align="center"
                  gap="5px"
                  onClick={() => this.openChannel()}
                >
                  <Ad size="15px" />
                  <Text size="xsmall" color="#000">
                    Channel 1
                  </Text>
                </Box>
                }
                <Box id="account-panel" direction="row" align="center" gap="xsmall">
                  <Image
                    src="avatar.png"
                    className="clickable"
                    onClick={(e) => this.toggleSidebar()}
                  />
                </Box>
              </Box>
            </Box>
            <Collapsible direction="horizontal" open={this.state.sidebarPanel}>
              <Layer className="sidebar-layer" full="vertical" position="right">
                <Box pad="20px" fill="vertical">
                  <Box align="end">
                    <Button
                      icon={<Close size="17px" color="#757575" />}
                      plain
                      onClick={() => this.toggleSidebar()}
                    />
                  </Box>
                  <Box className="account-panel" gap="50px" fill="vertical">
                    <Box align="center" gap="10px" flex={false}>
                      <Avatar src="profile.png" size="xlarge" />
                      <Box align="center" gap="2px">
                        {/* <Text>First & Last Name</Text> */}
                        <Text size="small">@{this.props.user.data.username}</Text>
                      </Box>
                    </Box>
                    <Box
                      className="account-panel-menu"
                      gap="10px"
                      justify="between"
                      pad={{ horizontal: "15px" }}
                      flex="grow"
                    >
                      <Box flex="grow" overflow={{ vertical: "auto" }}>
                        <Anchor
                          onClick={() => this.props.history.replace({ pathname: "/edit-profile" })}
                        >
                          <Box direction="row" align="center" gap="12px">
                            <User size="16px" />
                            <Text size="small">Edit Profile</Text>
                          </Box>
                        </Anchor>
                      </Box>
                      <Box>
                        <Button
                          className="button-red"
                          label={
                            <Text size="xsmall" color="#ff4040">
                              Logout
                            </Text>
                          }
                          icon={<Logout size="15px" color="#ff4040" />}
                          size="small"
                          fill="horizontal"
                          onClick={() => this.doLogout()}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Layer>
            </Collapsible>
            {this.state.layerChannel && (
              <Layer
                className="layer-view small"
                full={false}
                onEsc={() => this.closeLayer()}
                onClickOutside={() => this.closeLayer()}
                margin={size !== "small" ? { vertical: "medium" } : "none"}
                position={size !== "small" ? "center" : "bottom"}
                round="large"
              >
                <Box className="close-layer" align="end">
                  <Anchor onClick={() => this.closeLayer()}>
                    <Box>
                      <Close size="small" />
                    </Box>
                  </Anchor>
                </Box>
                <Box className="layer-box-header" gap="10px" flex={false}>
                  <Text className="text-bold" size="large">
                    Select Channel
                  </Text>
                </Box>
                <Box className="layer-box-content channel-list" gap="25px" fill>
                  <Box gap="10px">
                    <Anchor color="#000">
                      <Box direction="row" align="center" gap="10px">
                        <Image src="avatar.png" height="25px" />
                        <Text>Channel Name 1</Text>
                      </Box>
                    </Anchor>
                    <Anchor color="#000">
                      <Box direction="row" align="center" gap="10px">
                        <Image src="avatar.png" height="25px" />
                        <Text>Channel Name 2</Text>
                      </Box>
                    </Anchor>
                    <Anchor color="#000" onClick={() => this.openAddChannel()}>
                      <Box direction="row" align="center" justify="center" gap="10px">
                        <Add size="11px" />
                        <Text size="small">Add new channel</Text>
                      </Box>
                    </Anchor>
                  </Box>
                  <Box direction="row" justify="end" gap="15px" fill="horizontal">
                    <Button size="small" label="Cancel" onClick={() => this.closeLayer()} />
                    <Button primary size="small" label="Confirm" />
                  </Box>
                </Box>
              </Layer>
            )}
            {this.state.layerAddChannel && (
              <Layer
                className="layer-view small"
                full={false}
                onEsc={() => this.closeAddChannel()}
                onClickOutside={() => this.closeAddChannel()}
                margin={size !== "small" ? { vertical: "medium" } : "none"}
                position={size !== "small" ? "center" : "bottom"}
                round="large"
              >
                <Box className="close-layer" align="end">
                  <Anchor onClick={() => this.closeAddChannel()}>
                    <Box>
                      <Close size="small" />
                    </Box>
                  </Anchor>
                </Box>
                <Box className="layer-box-header" gap="10px" flex={false}>
                  <Text className="text-bold" size="large">
                    Create New Channel
                  </Text>
                </Box>
                <Box className="layer-box-content channel-list" gap="25px" fill>
                  <Box gap="20px">
                    <FormField label="Channel Name">
                      <TextInput type="text" placeholder="Enter channel name" />
                    </FormField>
                    <FormField className="select-input" label="Assign Inventory">
                      <Select options={["Option 1", "Option 2"]} placeholder="Select Inventory" />
                    </FormField>
                  </Box>
                  <Box direction="row" justify="end" gap="15px" fill="horizontal">
                    <Button size="small" label="Cancel" onClick={() => this.closeLayer()} />
                    <Button primary size="small" label="Confirm" />
                  </Box>
                </Box>
              </Layer>
            )}
          </>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    login: state.loginData,
    user: state.userData,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchLOGIN: (status, token) => {
      dispatch(login(status, token));
    },
    saveLogin: (id_user) => {
      dispatch(saveLogin(id_user));
    },
    fetchUser: (userdata) => {
      dispatch(fetchUser(userdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

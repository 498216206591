import React, { Component } from "react";
import styled from "styled-components";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Voucher extends Component {
  constructor(props) {
    super(props);

    if (this.props.label !== undefined) {
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = { allowdisp: true };
    }
  }

  renderItems() {
    let disp = this.state.items.map((dt) => {
      return (
        <TableRow>
          <TableCol className="tname">{dt.name}</TableCol>
          <TableCol className="align-right tqty">{dt.qty}</TableCol>
          <TableCol className="align-right tprice">
            IDR {numberFormat(dt.price)}
          </TableCol>
          <TableCol className="align-right ttotal">
            IDR {numberFormat(dt.price * dt.qty)}
          </TableCol>
        </TableRow>
      );
    });

    return disp;
  }

  convertDate(datestr) {
    let date_start = new Date(datestr.substr(0, 10));

    let start_year = date_start.getFullYear();
    let start_month = date_start.getMonth();
    let start_date = date_start.getDate();

    let returnstr =
      start_date.toString().padStart(2, "0") +
      " " +
      monthNames[start_month] +
      " " +
      start_year;

    return returnstr;
  }

  render() {
    return (
      <OuterWrap>
        <Wrapper>
          {this.state.allowdisp && (
            <>
              <Header>
                <HeaderLeft>
                  <HeaderLogo src="logo.png" />
                </HeaderLeft>
                <HeaderRight>
                  <HeaderInvoiceWrap>
                    <HeaderInvoice>Voucher</HeaderInvoice>
                    <HeaderInvoiceNo>Data ID: 123456789</HeaderInvoiceNo>
                  </HeaderInvoiceWrap>
                </HeaderRight>
              </Header>
              <Body>
                <Row>
                  <PaymentRow>
                    <PaymentLeft>Transaction Date </PaymentLeft>
                    <PaymentRight>22 September 2022</PaymentRight>
                  </PaymentRow>
                  <PaymentRow>
                    <PaymentLeft>Transaction Type </PaymentLeft>
                    <PaymentRight className="text-bold text-color-error">
                      Expense
                    </PaymentRight>
                  </PaymentRow>
                </Row>
                <Payment>
                  <Row>
                    <Text>Amount </Text>
                    <Text className="amount">Rp. 20.000</Text>
                  </Row>
                </Payment>
                <Row>
                  <PaymentLeft>Description </PaymentLeft>
                  <PaymentRight>
                    Bayar Parkir dan bensin kirim barang ke JNE
                  </PaymentRight>
                </Row>
              </Body>
              <Footer>
                <FooterRow className="align-center">
                  <FooterId>
                    <Text className="text-bold">Company Name</Text>
                    <RegularText> - </RegularText>
                    <RegularText>Address: Full Address</RegularText>
                    <RegularText> - </RegularText>
                    <RegularText>Phone: (021) 987654321</RegularText>
                  </FooterId>
                </FooterRow>
              </Footer>
            </>
          )}
        </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  width: 100%; /* Development preview */
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */

  /*width: 14cm;*/

  width: 12.5cm;

  /*height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;*/

  margin: 0 auto;
  background-color: #fff;
  border: 2px solid black;

  /* padding:5mm; */
  padding: 3mm;

  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;

  &.text-bold {
    font-weight: bold;
  }

  &.text-italic {
    font-style: italic;
  }
  &.text-color-warning {
    color: #f59e0b;
  }
  &.text-color-success {
    color: #388e3c;
  }
  &.text-color-error {
    color: #ef4444;
  }

  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 29%;
  float: left;

  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 70%;
  margin: auto;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const HeaderInvoiceWrap = styled.div`
  display: block;
  width: 100%;
`;

const HeaderInvoice = styled.h1`
  text-align: right;
  margin-bottom: 1.5mm;
  font-size: 2em;
`;

const HeaderInvoiceNo = styled.div`
  font-size: 1.3em;
  text-align: right;
  margin-bottom: 1.5mm;
`;

const Body = styled.div`
  display: block;
  position: relative;

  padding: 2mm 0;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const Row = styled.div`
  display: block;
  position: relative;

  /*CLEARFIX*/
  overflow: visible;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const Text = styled.div`
  margin-bottom: 1mm;
  font-size: 0.9em;

  &.amount {
    font-size: 1.5em !important;
    font-weight: bold;
  }
`;

const Payment = styled.div`
  display: block;
  position: relative;
  text-align: right;
  padding: 2mm;
  margin: 3mm 0;
  background: #f3f4f8;
  border: 1px solid #ddd;
`;

const PaymentRow = styled.div`
  display: block;
  position: relative;
  width: 100%;

  padding-top: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const PaymentLeft = styled.div`
  display: block;
  position: relative;
  width: 24%;
  float: left;

  &:after {
    position: absolute;
    content: ":";
    right: 0;
  }
`;

const PaymentRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  padding-left: 5%;
  float: left;
`;

const TableRow = styled.tr`
  display: table-row;
  position: relative;
  width: 100%;
`;

const TableCol = styled.td`
  display: table-cell;
  position: relative;
  padding: 1%;
  vertical-align: top;
  font-size: 1em;

  &.align-right {
    text-align: right;
  }
  &.tname {
    width: 40%;
  }
  &.tqty {
    width: 10%;
  }
  &.tprice {
    width: 25%;
  }
  &.ttotal {
    width: 25%;
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
`;

const Footer = styled.div`
  display: block;
  margin-top: 2mm;
`;

const FooterRow = styled.div`
  display: block;
  position: relative;
  width: 100%;

  padding-top: 2mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const FooterId = styled.div`
  border-top: 1px solid black;
  padding: 2mm 1.5mm;

  > div {
    display: inline-block;
    margin: 1mm 0.5mm;
  }
`;

export default Voucher;

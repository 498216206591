import React, { Component, Fragment } from "react";
import styled from "styled-components";

import moment from "moment";

// funsgsi untuk merubah int menjadi bentuk string. contoh 15000 menjadi 15,000
function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class PrintSalesmanInvoice extends Component {
  constructor(props) {
    super(props);

    if (this.props.label !== undefined) {
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = { allowdisp: false };
    }
  }

  renderItems() {
    let disp = this.state.up_detail.map((dt, idx) => {
      // let displayinv = prev != dt.po.id_purchase_order ? true : false;
      // prev = dt.po.id_purchase_order;

      return (
        <Fragment key={idx}>
          {dt.spacer === "yes" && (
            <TableRow>
              <TableCol style={{ color: "white" }}>-</TableCol>
              <TableCol></TableCol>
              <TableCol></TableCol>
              <TableCol></TableCol>
              <TableCol></TableCol>
              <TableCol></TableCol>
              <TableCol></TableCol>
              <TableCol></TableCol>
              <TableCol></TableCol>
              <TableCol></TableCol>
            </TableRow>
          )}
          <TableRow>
            <TableCol>{dt.displaycust ? dt.no : ""}</TableCol>
            <TableCol>{dt.displaycust ? dt.customer : ""}</TableCol>
            <TableCol>
              {dt.displaycust ? dt.up_s_o.up_customer.city : ""}
            </TableCol>
            <TableCol>{dt.no_invoice}</TableCol>
            <TableCol>{moment(dt.invoice_date).format("DD MMM YYYY")}</TableCol>
            <TableCol className="align-right">
              {numberFormat(dt.totalinvoice.toFixed(2))}
            </TableCol>
            <TableCol></TableCol>
            <TableCol></TableCol>
            <TableCol></TableCol>
            <TableCol></TableCol>
            {/*<TableCol className="align-center tqty">{dt.quantity}</TableCol></TableCol>*/}
          </TableRow>
        </Fragment>
      );
    });

    return disp;
  }

  render() {
    return (
      <OuterWrap>
        <Wrapper>
          {this.state.allowdisp && (
            <>
              <Header>
                <HeaderLeft>
                  <HeaderLogo src="logo.png" />
                </HeaderLeft>
                <HeaderRight>
                  <HeaderInvoiceWrap>
                    <HeaderInvoice>Salesman Invoice List</HeaderInvoice>
                    <HeaderInvoiceNo>&nbsp;</HeaderInvoiceNo>
                  </HeaderInvoiceWrap>
                </HeaderRight>
              </Header>
              <Supplier>
                <SupplierRow>
                  <SupplierLeft>Print Date </SupplierLeft>
                  <SupplierRight>
                    {moment().format("DD MMM YYYY HH:mm:ss")}
                  </SupplierRight>
                </SupplierRow>
                <SupplierRow>
                  <SupplierLeft>Salesman </SupplierLeft>
                  <SupplierRight>{this.state.filtersalesman.lab}</SupplierRight>
                </SupplierRow>
                <SupplierRow>
                  <SupplierLeft>Zone </SupplierLeft>
                  <SupplierRight>{this.state.filterzone.lab}</SupplierRight>
                </SupplierRow>
                <SupplierRow>
                  <SupplierLeft>Periode </SupplierLeft>
                  <SupplierRight>
                    {moment(this.state.sdate).format("DD MMM YYYY") +
                      " s.d. " +
                      moment(this.state.edate).format("DD MMM YYYY")}
                  </SupplierRight>
                </SupplierRow>
                {/*
          <SupplierRow>
            <SupplierLeft>Est. Finish Date </SupplierLeft>
            <SupplierRight>28 September 2022</SupplierRight>
          </SupplierRow>
          */}
              </Supplier>
              <TableContents>
                <thead>
                  <TableRow>
                    <TableHeader width="1%">No</TableHeader>
                    <TableHeader width="15%">Nama Toko</TableHeader>
                    <TableHeader width="10%">Kota</TableHeader>
                    <TableHeader width="8%">No. Invoice</TableHeader>
                    <TableHeader width="8%">Tgl. Invoice</TableHeader>
                    <TableHeader width="9%">Jumlah Tagihan</TableHeader>
                    <TableHeader width="5%">TT</TableHeader>
                    <TableHeader width="10%">Tunai</TableHeader>
                    <TableHeader width="17%">BG/Cek/Transfer</TableHeader>
                    <TableHeader width="17%">Catatan</TableHeader>
                  </TableRow>
                </thead>
                <tbody>{this.renderItems()}</tbody>
              </TableContents>
              <PageFoot>
                HALAMAN {this.state.page} DARI {this.state.totalpage}
              </PageFoot>
            </>
          )}
        </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  width: 100%; /* Development preview */
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */

  /*width: 14cm;*/

  width: 27cm;
  height: 20.7cm;

  /*height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;*/

  margin: 0 auto;
  border: 2px solid black;

  /* padding:5mm; */
  padding: 30px;

  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;

  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 29%;
  float: left;

  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 70%;
  margin: auto;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const HeaderInvoiceWrap = styled.div`
  display: block;
  width: 100%;
`;

const HeaderInvoice = styled.h1`
  text-align: right;
  margin-bottom: 1.5mm;
  font-size: 2em;
`;

const HeaderInvoiceNo = styled.div`
  font-size: 1.3em;
  text-align: right;
  margin-bottom: 1.5mm;
`;

const Supplier = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 3mm;
  padding-bottom: 5mm;
`;

const SupplierRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const SupplierLeft = styled.div`
  display: block;
  position: relative;
  width: 22%;
  float: left;

  &:after {
    position: absolute;
    content: ":";
    right: 0;
  }
`;

const SupplierRight = styled.div`
  display: block;
  position: relative;
  width: 72%;
  padding-left: 5%;
  float: left;

  &.boldred {
    font-weight: bold;
    color: red;
  }
`;

const TableContents = styled.table`
  display: table;
  position: relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size: 11px;
`;

const TableRow = styled.tr`
  display: table-row;
  position: relative;
  width: 100%;
`;

const TableHeader = styled.th`
  display: table-cell;
  position: relative;
  background: black;
  color: white;
  margin-bottom: 2mm;
  font-size: 1.2em;
  padding: 1%;
`;

const TableCol = styled.td`
  display: table-cell;
  position: relative;
  padding: 5px;
  vertical-align: top;
  font-size: 1em;

  border: 1px solid black;

  &.align-right {
    text-align: right;
  }
  &.tpo {
    width: 20%;
  }
  &.tname {
    width: 40%;
    white-space: pre-wrap;
  }
  &.tqty {
    width: 10%;
  }
  &.tprice {
    width: 12%;
  }
  &.ttotal {
    width: 12%;
  }
  &.tso {
    width: 40%;
    white-space: pre-wrap;
  }
`;

const PageFoot = styled.div`
  display: block;
  position: absolute;
  bottom: 5mm;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
`;

export default PrintSalesmanInvoice;

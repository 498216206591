import React, { Component } from "react";
import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

import { Dashboard, Basket, Achievement, Money } from "grommet-icons";

class FrontEndSidebarMenu extends Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <Navigation
          // you can use your own router's api to get pathname
          activeItemId="/dashboard"
          onSelect={({ itemId }) => {
            if (itemId.toString().length > 1) {
              this.props.history.replace(itemId);
            }
          }}
          items={[
            {
              title: "Home",
              itemId: "/home",
              elemBefore: () => <Dashboard />,
            },
            {
              title: "Sales",
              itemId: "a",
              elemBefore: () => <Basket />,
              subNav: [
                {
                  title: "Sales Order",
                  itemId: "/home-sales-order",
                },
                {
                  title: "New Sales Order",
                  itemId: "/home-add-sales",
                },
              ],
            },
            {
              title: "Finance",
              itemId: "b",
              elemBefore: () => <Money />,
              subNav: [
                {
                  title: "Budget Request",
                  itemId: "/home-budget-request",
                },
              ],
            },
            {
              title: "Achievement",
              itemId: "c",
              elemBefore: () => <Achievement />,
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrontEndSidebarMenu);

import React, { Component } from "react";
import styled from "styled-components";

import Barcode from "react-barcode";

class ShippingLabel extends Component {
  constructor(props) {
    super(props);

    if (this.props.label !== undefined) {
      this.state = this.props.label;
      this.state.nokoli = this.props.nokoli;
      this.state.allowdisp = true;
    } else {
      this.state = { allowdisp: false };
    }

    // this.state = {
    //     customername: "Adeline Devina",
    //     customerphone: "08112345678",
    //     customeraddress: "Jl. Wisma Sari XVI no. 9, Kec. Ngaliyan, Kota Semarang, Jawa Tengah",
    //     invoice_no: "S-TP-00000232 (INV/20210713/MPL/1404828096)",
    //     total_items: 5,
    //     total_koli: 2,
    //     courier: "JNE JNE Trucking",
    //     resi: "A92183901839012",
    //     koli: [
    //       {
    //         package: 2,
    //         items:[
    //           {
    //             qty: 2,
    //             name: "Produk A",
    //           },
    //           {
    //             qty: 1,
    //             name: "Produk B",
    //           },
    //         ]
    //       },
    //       {
    //         package: 1,
    //         items:[
    //           {
    //             qty: 5,
    //             name: "Produk C",
    //           },
    //         ]
    //       },
    //       {
    //         package: 1,
    //         items:[
    //           {
    //             qty: 1,
    //             name: "Produk D",
    //           },
    //           {
    //             qty: 5,
    //             name: "Produk X",
    //           },
    //         ]
    //       },
    //       {
    //         package: 1,
    //         items:[
    //           {
    //             qty: 2,
    //             name: "Produk D",
    //           },
    //           {
    //             qty: 2,
    //             name: "Produk F",
    //           },
    //         ]
    //       }
    //     ],
    // }
  }

  renderKoli() {
    if (this.state.koli.length > 0) {
      let i;

      let dispbox = [];

      for (i = 0; i < this.state.koli.length; i += 2) {
        let leftkoli = this.state.koli[i].items.map((dt) => {
          return <TableRegularText>{dt.qty + "x " + dt.name}</TableRegularText>;
        });

        if (this.state.koli[i + 1] !== undefined) {
          let rightkoli = this.state.koli[i + 1].items.map((dt) => {
            return (
              <TableRegularText>{dt.qty + "x " + dt.name}</TableRegularText>
            );
          });

          dispbox.push(
            <TableRow>
              <TableCol>
                <TableBoldText>
                  Koli {i + 1}{" "}
                  {this.state.koli[i].package > 1
                    ? "(aktual: " + this.state.koli[i].package + " koli)"
                    : ""}
                </TableBoldText>
                {leftkoli}
              </TableCol>
              <TableCol>
                <TableBoldText>Koli {i + 2}</TableBoldText>
                {rightkoli}
              </TableCol>
            </TableRow>
          );
        } else {
          dispbox.push(
            <TableRow>
              <TableCol>
                <TableBoldText>Koli {i + 1}</TableBoldText>
                {leftkoli}
              </TableCol>
              <TableCol></TableCol>
            </TableRow>
          );
        }
      }

      return dispbox;

      //return disp;
    }
  }

  render() {
    return (
      <OuterWrap>
        <Wrapper>
          {this.state.allowdisp && (
            <>
              <Header>
                <HeaderLeft>
                  <HeaderLogo src="logo-upspace.png" />
                </HeaderLeft>
                <HeaderRight>
                  <HeaderText>
                    This package is happy to see you,
                    <br />
                    {this.state.customername}!
                    <br />
                    <br />
                    www.upspace.id | ig: upspace.id
                  </HeaderText>
                </HeaderRight>
              </Header>
              <Recipient>
                <RecipientRow>
                  <RecipientLeft>To </RecipientLeft>
                  <RecipientRightLarge>
                    {this.state.customername.toUpperCase()}
                  </RecipientRightLarge>
                </RecipientRow>
                <RecipientRow>
                  <RecipientLeft>Phone </RecipientLeft>
                  <RecipientRight>{this.state.customerphone}</RecipientRight>
                </RecipientRow>
                <RecipientRow>
                  <RecipientLeft>Address </RecipientLeft>
                  <RecipientRight>{this.state.customeraddress}</RecipientRight>
                </RecipientRow>
                <RecipientRow>
                  <TextRow>Invoice {this.state.invoice_no}</TextRow>
                </RecipientRow>
              </Recipient>
              <Totals>
                <TotalLeft>Total Items: {this.state.total_items}</TotalLeft>
                <TotalRight>Total Koli: {this.state.total_koli}</TotalRight>
              </Totals>
              <TableContents>{this.renderKoli()}</TableContents>
              <Courier>
                <RegularText>Courier: {this.state.courier}</RegularText>
                <Barcode value={this.state.resi} height="50" fontSize="10" />
              </Courier>
              <Footer>
                <FooterLeft>
                  <RegularText>Notes:</RegularText>
                  <BoldText>{this.state.notes}</BoldText>
                </FooterLeft>
                <FooterRight>
                  <RegularText>No. Koli</RegularText>
                  <BoldText className="nokoli">{this.state.nokoli}</BoldText>
                </FooterRight>
              </Footer>
            </>
          )}
        </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div``;
const Wrapper = styled.div`
  display: block;
  position: relative;
  /*width: 14cm;*/
  width: 12.5cm;
  height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;
  border: 2px solid black;

  padding: 5mm;

  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1em;

  /*@page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid black;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 23%;
  float: left;

  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 60%;
  margin: auto;
  margin-bottom: 1mm;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 72%;
  float: left;
`;

const HeaderText = styled.p`
  text-align: center;
  margin-bottom: 1mm;
`;

const Recipient = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 3mm;
  border-bottom: 1px solid black;
`;

const RecipientRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 3mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const RecipientLeft = styled.div`
  display: block;
  position: relative;
  width: 13%;
  float: left;

  &:after {
    position: absolute;
    content: ":";
    right: 0;
  }
`;

const RecipientRightLarge = styled.div`
  display: block;
  position: relative;
  width: 81%;
  padding-left: 5%;
  float: left;
  font-size: 1.3em;
  font-weight: bold;
`;

const RecipientRight = styled.div`
  display: block;
  position: relative;
  width: 81%;
  padding-left: 5%;
  float: left;
`;

const TextRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  font-weight: bold;
`;

const Totals = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 3mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const TotalLeft = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const TotalRight = styled.div`
  display: block;
  position: relative;
  width: 30%;
  float: right;
`;

const TableContents = styled.table`
  display: table;
  position: relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size: 11px;
`;

const TableRow = styled.tr`
  display: table-row;
  position: relative;
  height: 25mm;
  width: 100%;
`;

const TableCol = styled.td`
  display: table-cell;
  position: relative;
  width: 48%;
  padding: 1%;
  vertical-align: top;
  margin: 0;
  border: 1px solid black;
  border-spacing: 0;
  font-size: 0.9em;
`;

const TableBoldText = styled.div`
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 0.9em !important;
`;

const TableRegularText = styled.div`
  padding: 0;
  margin: 2mm 1mm;
  font-size: 0.9em !important;
`;

const BoldText = styled.div`
  font-weight: bold;
  padding: 0;
  margin: 0;

  &.nokoli {
    font-size: 2em;
    font-weight: bold;
    margin-left: 5mm;
    margin-top: 5mm;
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 2mm 1mm;
`;

const Courier = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

const Footer = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  width: 92%;
  border-top: 1px solid black;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const FooterLeft = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;

  height: 20mm;

  border-right: 1px solid black;
`;

const FooterRight = styled.div`
  display: block;
  position: relative;
  width: 25%;
  float: left;
`;

export default ShippingLabel;

import React, { Component } from "react";
import styled from "styled-components";

import moment from "moment";

class PrintPurchaseOrderChecklist extends Component {
  constructor(props) {
    super(props);

    if (this.props.label !== undefined) {
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = { allowdisp: false };
    }
  }

  renderItems() {
    let disp = this.state.up_detail.map((dt, idx) => {
      if (dt.quantity - dt.received_quantity !== 0) {
        return (
          <TableRow key={idx}>
            <TableCol className="tname">{dt.variant_name}</TableCol>
            <TableCol className="align-center tqty">
              <strong>
                {dt.quantity}
                {dt.up_variant.up_item.up_unit !== null
                  ? dt.up_variant.up_item.up_unit.unit
                  : ""}
              </strong>
              {dt.up_variant.up_item.up_unit3 !== null && (
                <>
                  <br />(
                  <em>
                    {parseFloat(
                      dt.quantity / dt.up_variant.unit_conversion2
                    ).toFixed(2)}{" "}
                    {dt.up_variant.up_item.up_unit3.unit}
                  </em>
                  )
                </>
              )}
            </TableCol>
            <TableCol className="align-center tqty" style={{ color: "blue" }}>
              <strong>
                {dt.received_quantity}
                {dt.up_variant.up_item.up_unit !== null
                  ? dt.up_variant.up_item.up_unit.unit
                  : ""}
              </strong>
              {dt.up_variant.up_item.up_unit3 !== null && (
                <>
                  <br />(
                  <em>
                    {parseFloat(
                      dt.received_quantity / dt.up_variant.unit_conversion2
                    ).toFixed(2)}{" "}
                    {dt.up_variant.up_item.up_unit3.unit}
                  </em>
                  )
                </>
              )}
            </TableCol>
            <TableCol className="align-center tqty" style={{ color: "red" }}>
              <strong>
                {dt.quantity - dt.received_quantity}
                {dt.up_variant.up_item.up_unit !== null
                  ? dt.up_variant.up_item.up_unit.unit
                  : ""}
              </strong>
              {dt.up_variant.up_item.up_unit3 !== null && (
                <>
                  <br />(
                  <em>
                    {parseFloat(
                      (dt.quantity - dt.received_quantity) /
                        dt.up_variant.unit_conversion2
                    ).toFixed(2)}{" "}
                    {dt.up_variant.up_item.up_unit3.unit}
                  </em>
                  )
                </>
              )}
            </TableCol>
            {/*
            <TableCol className="align-right tprice">IDR 250.000</TableCol>
            <TableCol className="align-right ttotal">IDR 750.000</TableCol>
            */}
          </TableRow>
        );
      }
      return disp;
    });
  }

  render() {
    return (
      <OuterWrap>
        <Wrapper>
          {this.state.allowdisp && (
            <>
              <Header>
                <HeaderLeft>
                  <HeaderLogo src="logo.png" />
                </HeaderLeft>
                <HeaderRight>
                  <HeaderSupplierName>
                    {this.state.up_supplier.supplier_name}
                  </HeaderSupplierName>
                  <HeaderInvoiceWrap>
                    <HeaderInvoice>ITEM CHECKLIST</HeaderInvoice>
                    <HeaderInvoiceNo>
                      No: {this.state.no_invoice_po}
                    </HeaderInvoiceNo>
                  </HeaderInvoiceWrap>
                </HeaderRight>
              </Header>
              <Supplier>
                <SupplierRow>
                  <SupplierLeft>Checklist Print Date </SupplierLeft>
                  <SupplierRight>
                    {moment().format("DD MMM YYYY HH:mm:ss")}
                  </SupplierRight>
                </SupplierRow>
                <SupplierRow>
                  <SupplierLeft>Supplier </SupplierLeft>
                  <SupplierRight>
                    {this.state.up_supplier.supplier_name},{" "}
                    {this.state.up_supplier.city}
                  </SupplierRight>
                </SupplierRow>
                <SupplierRow>
                  <SupplierLeft>&nbsp;</SupplierLeft>
                  <SupplierRight className="boldred">
                    NOT TO BE SENT TO SUPPLIER!
                  </SupplierRight>
                  <HeaderInvoiceNo>
                    Halaman {this.state.page} dari {this.state.totalpage}
                  </HeaderInvoiceNo>
                </SupplierRow>
                {/*
          <SupplierRow>
            <SupplierLeft>Est. Finish Date </SupplierLeft>
            <SupplierRight>28 September 2022</SupplierRight>
          </SupplierRow>
          */}
              </Supplier>
              <TableContents>
                <TableRow>
                  <TableHeader>ITEM DESCRIPTION</TableHeader>
                  <TableHeader>ORDER QTY</TableHeader>
                  <TableHeader>RECEIVED QTY</TableHeader>
                  <TableHeader>REMAINING QTY</TableHeader>
                </TableRow>
                <TableRow>
                  <TableCol />
                  <TableCol />
                  <TableCol />
                  <TableCol />
                </TableRow>
                {this.renderItems()}
              </TableContents>
            </>
          )}
        </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  width: 100%; /* Development preview */
`;

const Wrapper = styled.div`
  display: block;
  position: relative;
  height: auto; /* Development preview */

  /*width: 14cm;*/

  width: 20cm;
  height: 26.05cm;

  /*height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;*/

  margin: 0 auto;
  border: 2px solid black;

  /* padding:5mm; */
  padding: 30px;

  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1;

  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid black;
  padding-bottom: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 29%;
  float: left;

  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 70%;
  margin: auto;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const HeaderSupplierName = styled.div`
  display: block;
  width: 60%;
  margin-left: 0.25cm;
  margin-top: 0.25cm;
  font-size: 1.5em;
  font-weight: bold;
  float: left;
`;

const HeaderInvoiceWrap = styled.div`
  display: block;
  width: 100%;
`;

const HeaderInvoice = styled.h1`
  text-align: right;
  margin-bottom: 1.5mm;
  font-size: 2em;
`;

const HeaderInvoiceNo = styled.div`
  font-size: 1.3em;
  text-align: right;
  margin-bottom: 1.5mm;
`;

const Supplier = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 3mm;
  padding-bottom: 5mm;
`;

const SupplierRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 1mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const SupplierLeft = styled.div`
  display: block;
  position: relative;
  width: 22%;
  float: left;

  &:after {
    position: absolute;
    content: ":";
    right: 0;
  }
`;

const SupplierRight = styled.div`
  display: block;
  position: relative;
  width: 72%;
  padding-left: 5%;
  float: left;

  &.boldred {
    font-weight: bold;
    color: red;
  }
`;

const TableContents = styled.table`
  display: table;
  position: relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size: 11px;
`;

const TableRow = styled.tr`
  display: table-row;
  position: relative;
  width: 100%;
`;

const TableHeader = styled.th`
  display: table-cell;
  position: relative;
  background: black;
  color: white;
  margin-bottom: 2mm;
  font-size: 1.2em;
  padding: 1%;
`;

const TableCol = styled.td`
  display: table-cell;
  position: relative;
  padding: 1%;
  vertical-align: top;
  font-size: 1em;
  border: 1px solid black;

  &.align-right {
    text-align: right;
  }
  &.tname {
    width: 45%;
  }
  &.tqty {
    width: 10%;
  }
  &.tprice {
    width: 12%;
  }
  &.ttotal {
    width: 12%;
  }
  &.tso {
    width: 40%;
    white-space: pre-wrap;
  }
`;

export default PrintPurchaseOrderChecklist;

const theme = {
  global: {
    breakpoints: {
      small: {
        value: 767,
      },
    },
    font: {
      family: 'poppinsregular',
      size: '16px',
      height: '22px',
      maxWidth: '432px',
    },
    colors: {
      'text': '#333',
      'link': '#ff6755',
      'brand': '#039be6',
      'background': '#eef0f8',
      'border': '#999',
      'icon': '#333',
      'placeholder': '#999',
      'accent-1': '#ff6755',
      'accent-2': '#0D9488',
      'accent-3': '#9333EA',
      'accent-4': '#4F46E5',
      'light-1': '#f8f8f8',
      'light-2': '#f2f2f2',
      'light-3': '#ededed',
      'light-4': '#dadada',
      'light-5': '#dadada',
      'light-6': '#dadada',
      'dark-1': '#222',
      'dark-2': '#333',
      'dark-3': '#555',
      'dark-4': '#777',
      'dark-5': '#888',
      'dark-6': '#999',
    },
    input: {
      extend: {
        fontSize: '15px',
        fontWeight: 'normal',
        lineHeight: '15px',
        color: '#333333',
      },
      padding: {
        vertical: '12px',
        horizontal: '15px',
      },
    },
    hover: {
      color: '#777',
      background: '#f9f9f9',
    },
    focus: {
      border: {
        color: "none",
      },
      background: '#f9f9f9',
    },
    drop: {
      elevation: 'xsmall',
      extend: {
        color: '#000',
      },
    },
  },
  icon: {
    size: {
      xsmall: '12px',
      small: '14px',
      medium: '20px',
      large: '26px',
      xlarge: '32px',
    },
  },
  formField: {
    label: {
      size: '12px',
      weight: 'bold',
      color: 'accent-2',
      margin: {
        top: 'none',
        bottom: '7px',
        horizontal: 'none'
      }
    },
    border: {
      color: 'transparent',
      error: {
        color: 'transparent',
      },
    },
    error: {
      size: '14px',
      margin: {
        horizontal: 'none',
        bottom: 'none',
      }
    },
    margin: {
      bottom: '0px',
    },
  },
  textInput: {
    extend: {
      background: '#fff',
    },
  },
  select: {
    container: {
      extend: {
        background: '#fff',
      },
    },
    options: {
      text: {
        size: '14px',
        color: '#222',
      },
    },
    control: {
      extend: {
        border: "1px solid #cacaca",
        borderRadius: "7px",
      },
    },
    icons: {
      color: '#222222',
    },
    background: '#fff',
  },
  radioButton: {
      size: '20px',
  },
  fileInput: {
    message: {
      color: 'accent-2',
      textAlign: 'center',
    },
    background: '#f8f8f8',
    pad: {
      horizontal: '20px',
      vertical: '40px'
    },
    round: '5px',
  },
  anchor: {
    fontWeight: 'normal',
    color: 'brand',
    hover: {
      textDecoration: 'none',
    },
    extend: {
      lineHeight: '1',
    },
  },
  button: {
    extend: {
      fontWeight: 'normal',
      lineHeight: '1',
      letterSpacing: '0.5px',
    },
    default: {
      extend: {
        width: 'fit-content',
        color: "#333",
        background: '#fff',
        border: '1px solid #ccc',
      },
      padding: {
        vertical: '12px',
        horizontal: '20px',
      },
    },
    primary: {
      extend: {
        width: 'fit-content',
        color: "#fff",
        background: '#039be6',
      },
      padding: {
        vertical: '12px',
        horizontal: '20px',
      },
    },
    secondary: {
      extend: {
        width: 'fit-content',
        color: "#fff",
        background: '#0D9488',
      },
      padding: {
        vertical: '12px',
        horizontal: '20px',
      },
    },
    color: '#039be6',
    border: {
      width: '1px',
      color: '#039be6',
      radius: '9999px',
    },
    size: {
      small: {
        border: {
          radius: '9999px',
        },
      },
      medium: {
        border: {
          radius: '9999px',
        },
      },
      large: {
        border: {
          radius: '9999px',
        },
      },
    },
  },
  accordion: {
    icons: {
      color: '#222222',
    },
    border: {
      style: 'hidden',
      size: '0px',
    },
    panel: {
      border: {
        style: 'hidden',
        size: '0px',
      },
    },
  },
  pagination: {
    button: {
      color: '#333',
      active: {
        color: '#aaa',
        background: {
          color: '#eee',
        },
      },
    },
  },
  heading: {
    level: {
      "1": {
        "font": {
          family: 'poppinsbold',
        },
        "small": {
          "size": "34px",
          "height": "38px",
          "maxWidth": "816px"
        },
        "medium": {
          "size": "50px",
          "height": "54px",
          "maxWidth": "1200px"
        },
        "large": {
          "size": "82px",
          "height": "86px",
          "maxWidth": "1968px"
        },
        "xlarge": {
          "size": "114px",
          "height": "118px",
          "maxWidth": "2736px"
        }
      },
      "2": {
        "font": {
          family: 'poppinsbold',
        },
        "small": {
          "size": "26px",
          "height": "30px",
          "maxWidth": "624px"
        },
        "medium": {
          "size": "34px",
          "height": "38px",
          "maxWidth": "816px"
        },
        "large": {
          "size": "50px",
          "height": "54px",
          "maxWidth": "1200px"
        },
        "xlarge": {
          "size": "66px",
          "height": "70px",
          "maxWidth": "1584px"
        }
      },
      "3": {
        "font": {
          family: 'poppinsbold',
        },
        "small": {
          "size": "22px",
          "height": "26px",
          "maxWidth": "528px"
        },
        "medium": {
          "size": "26px",
          "height": "30px",
          "maxWidth": "624px"
        },
        "large": {
          "size": "34px",
          "height": "38px",
          "maxWidth": "816px"
        },
        "xlarge": {
          "size": "42px",
          "height": "46px",
          "maxWidth": "1008px"
        }
      },
      "4": {
        "font": {
          family: 'poppinsmedium',
        },
        "small": {
          "size": "20px",
          "height": "24px",
          "maxWidth": "432px"
        },
        "medium": {
          "size": "20px",
          "height": "24px",
          "maxWidth": "432px"
        },
        "large": {
          "size": "20px",
          "height": "24px",
          "maxWidth": "432px"
        },
        "xlarge": {
          "size": "20px",
          "height": "24px",
          "maxWidth": "432px"
        }
      },
      "5": {
        "font": {
          family: 'poppinssemibold',
        },
        "small": {
          "size": "18px",
          "height": "22px",
          "maxWidth": "384px"
        },
        "medium": {
          "size": "18px",
          "height": "22px",
          "maxWidth": "384px"
        },
        "large": {
          "size": "18px",
          "height": "22px",
          "maxWidth": "384px"
        },
        "xlarge": {
          "size": "18px",
          "height": "22px",
          "maxWidth": "384px"
        }
      },
      "6": {
        "font": {
          family: 'poppinssemibold',
        },
        "small": {
          "size": "16px",
          "height": "20px",
          "maxWidth": "336px"
        },
        "medium": {
          "size": "16px",
          "height": "20px",
          "maxWidth": "336px"
        },
        "large": {
          "size": "16px",
          "height": "20px",
          "maxWidth": "336px"
        },
        "xlarge": {
          "size": "16px",
          "height": "20px",
          "maxWidth": "336px"
        }
      }
    },
  },
  text: {
    "xxsmall": {
      "size": "10px",
      "height": "15px",
      "maxWidth": "288px"
    },
    "xsmall": {
      "size": "12px",
      "height": "18px",
      "maxWidth": "288px"
    },
    "small": {
      "size": "14px",
      "height": "21px",
      "maxWidth": "336px"
    },
    "medium": {
      "size": "16px",
      "height": "24px",
      "maxWidth": "432px"
    },
    "large": {
      "size": "20px",
      "height": "30px",
      "maxWidth": "528px"
    },
    "xlarge": {
      "size": "26px",
      "height": "39px",
      "maxWidth": "624px"
    },
    "xxlarge": {
      "size": "30px",
      "height": "45px",
      "maxWidth": "816px"
    },
  },
  paragraph: {
    "xxsmall": {
      "size": "10px",
      "height": "15px",
      "maxWidth": "288px"
    },
    "xsmall": {
      "size": "12px",
      "height": "18px",
      "maxWidth": "288px"
    },
    "small": {
      "size": "14px",
      "height": "21px",
      "maxWidth": "336px"
    },
    "medium": {
      "size": "16px",
      "height": "24px",
      "maxWidth": "432px"
    },
    "large": {
      "size": "20px",
      "height": "30px",
      "maxWidth": "528px"
    },
    "xlarge": {
      "size": "26px",
      "height": "39px",
      "maxWidth": "624px"
    },
    "xxlarge": {
      "size": "30px",
      "height": "45px",
      "maxWidth": "816px"
    },
  },
  notification: {
    container: {
      background: {
        color: '#fff8e1',
      },
      pad: '8px',
      round: '10px',
      elevation: 'small',
    },
    title: {
      size: 'small',
    },
    message: {
      size: 'small',
    },
  },
  calendar: {
    heading: {
      level: '6',
    },
    day: {
      extend: {
        textAlign: 'center',
      },
    },
    extend: {
      padding: '10px',
    },
  },
};

export default theme;



